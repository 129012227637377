// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dubbyButtonConnected {
    border: 2px solid green;
    border-radius: 30px;
    color: green;
    height: 22px;
    padding: 0px 6px 0px 6px;
    font-size: 12px;
    font-weight: bold;
}

.dubbyButtonNotConnected {
    border: 2px solid red;
    border-radius: 30px;
    color: red;
    height: 22px;
    padding: 0px 6px 0px 6px;
    font-size: 12px;
    font-weight: bold;
}

.dubbyButtonConnected p, .dubbyButtonNotConnected p {
    position: relative;
    bottom: 11.7px;
    -webkit-user-select: none;
            user-select: none;
}`, "",{"version":3,"sources":["webpack://./src/components/dubbyConnectedButton.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,YAAY;IACZ,YAAY;IACZ,wBAAwB;IACxB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,mBAAmB;IACnB,UAAU;IACV,YAAY;IACZ,wBAAwB;IACxB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,yBAAiB;YAAjB,iBAAiB;AACrB","sourcesContent":[".dubbyButtonConnected {\n    border: 2px solid green;\n    border-radius: 30px;\n    color: green;\n    height: 22px;\n    padding: 0px 6px 0px 6px;\n    font-size: 12px;\n    font-weight: bold;\n}\n\n.dubbyButtonNotConnected {\n    border: 2px solid red;\n    border-radius: 30px;\n    color: red;\n    height: 22px;\n    padding: 0px 6px 0px 6px;\n    font-size: 12px;\n    font-weight: bold;\n}\n\n.dubbyButtonConnected p, .dubbyButtonNotConnected p {\n    position: relative;\n    bottom: 11.7px;\n    user-select: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer {
    display: grid;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 120px;
    border-top: 1px solid #383838;
}

.footerText {
    font-size: 14px;
}

.icons {
    position: relative;
    bottom: 10px;
}

.icons a {
    text-decoration: none;
    color: white;
    padding: 5px;
}


.smallText {
    font-size: 10px;
    padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/footer.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,qBAAqB;IACrB,YAAY;IACZ,YAAY;AAChB;;;AAGA;IACI,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".footer {\n    display: grid;\n    align-items: center;\n    text-align: center;\n    width: 100%;\n    height: 120px;\n    border-top: 1px solid #383838;\n}\n\n.footerText {\n    font-size: 14px;\n}\n\n.icons {\n    position: relative;\n    bottom: 10px;\n}\n\n.icons a {\n    text-decoration: none;\n    color: white;\n    padding: 5px;\n}\n\n\n.smallText {\n    font-size: 10px;\n    padding-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

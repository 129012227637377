import React from 'react'
import ReactPlayer from 'react-player'

function YouTubeItem({ id }) {

  return (
    <div className='playerWrapper'>
        <ReactPlayer 
            url={"https://www.youtube.com/watch?v=" + id}
            light = {true}
            className = "player"
            playing
            width="100%"
        />
    </div>
  )

}

export default YouTubeItem
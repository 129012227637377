import React, { useState } from 'react';
import './homePage.css'
import PluginGrid from '../components/PluginGrid';

function HomePage({ navActive }) {

  return (
    <div>
        <PluginGrid navActive={navActive}/>
    </div>
  )
}

// function handleChangeFile(file) {
//     let reader = new FileReader();
//     reader.onload = function () {
//       //binContent = reader.result;
//     }
//     reader.readAsArrayBuffer(file);
//   }

export default HomePage
import React from 'react'
import './pluginItem.css';
import { Link } from 'react-router-dom';

function PluginItem({ item }) {

  return (
    <div className='pluginItem'>
        <Link to={item.released ? `/plugin/${item._id}` : "/"} className='pluginLink' style={!item.released ? {pointerEvents: "none", touchAction: "none"} : {pointerEvents: "auto", touchAction: "auto"}}>
          <div>
            {!item.released ?
              <div className='comingsoon'>
                <p>COMING SOON</p>
              </div> : <div></div>
            }
            <img src={item.image + "_small.png"} alt="img" className="pluginImage" />
          </div>
        </Link>
    </div>
  )
}

export default PluginItem;


import React from 'react'
import './footer.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookSquare, faInstagram, faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons'
import { faGlobe } from '@fortawesome/free-solid-svg-icons'

function Footer() {
  return (
    <div className="footer">
        <p className='footerText'>Follow Componental</p>
        <div className="icons">
            <a href="https://www.componental.co/" target='_blank'><FontAwesomeIcon icon={faGlobe} size="xl" /></a>
            <a href="https://www.youtube.com/@Componental" target='_blank'><FontAwesomeIcon icon={faYoutube} size="xl" /></a>
            <a href="https://www.instagram.com/componental.co/" target='_blank'><FontAwesomeIcon icon={faInstagram} size="xl" /></a>
            <a href="https://www.facebook.com/profile.php?id=100092684233404" target='_blank'><FontAwesomeIcon icon={faFacebookSquare} size="xl" /></a>
            <a href="https://linkedin.com/company/componental" target='_blank'><FontAwesomeIcon icon={faLinkedin} size="xl" /></a>
        </div>
        <div className="smallText">
          Componental ApS • Copyright © 2024 • All rights reserved
        </div>
    </div>
  )
}

export default Footer
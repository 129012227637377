// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  #console {
    color: white;
    font-family: "Consolas";
    background-color: black;
    margin: 0.5em;
    padding: 0.5em;
    height: 6.5em;
    width: 70vw;
    overflow: auto;
    border-radius: 0.5em;
  }
  
  .flashBtn {
    font-family: "CompoFont", sans-serif;
    font-size: 13pt;
    background-color: white;
    color: #212121;
    padding: 0.5em 0.5em;
    border-radius: 0.5em;
    border-style: hidden;
    cursor: pointer;
    float: left;
  
    &:disabled {
      background-color: #2D2E2F;
      cursor: default;
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
  
    to {
      transform: rotate(360deg);
    }
  }`, "",{"version":3,"sources":["webpack://./src/pages/homePage.css"],"names":[],"mappings":";;EAEE;IACE,cAAc;IACd,oBAAoB;EACtB;;EAEA;IACE;MACE,4CAA4C;IAC9C;EACF;;EAEA;IACE,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,cAAc;EAChB;;EAEA;IACE,YAAY;IACZ,uBAAuB;IACvB,uBAAuB;IACvB,aAAa;IACb,cAAc;IACd,aAAa;IACb,WAAW;IACX,cAAc;IACd,oBAAoB;EACtB;;EAEA;IACE,oCAAoC;IACpC,eAAe;IACf,uBAAuB;IACvB,cAAc;IACd,oBAAoB;IACpB,oBAAoB;IACpB,oBAAoB;IACpB,eAAe;IACf,WAAW;;IAEX;MACE,yBAAyB;MACzB,eAAe;IACjB;EACF;;EAEA;IACE;MACE,uBAAuB;IACzB;;IAEA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":["\n  \n  .App-logo {\n    height: 40vmin;\n    pointer-events: none;\n  }\n  \n  @media (prefers-reduced-motion: no-preference) {\n    .App-logo {\n      animation: App-logo-spin infinite 20s linear;\n    }\n  }\n  \n  .App-header {\n    min-height: 100vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .App-link {\n    color: #61dafb;\n  }\n  \n  #console {\n    color: white;\n    font-family: \"Consolas\";\n    background-color: black;\n    margin: 0.5em;\n    padding: 0.5em;\n    height: 6.5em;\n    width: 70vw;\n    overflow: auto;\n    border-radius: 0.5em;\n  }\n  \n  .flashBtn {\n    font-family: \"CompoFont\", sans-serif;\n    font-size: 13pt;\n    background-color: white;\n    color: #212121;\n    padding: 0.5em 0.5em;\n    border-radius: 0.5em;\n    border-style: hidden;\n    cursor: pointer;\n    float: left;\n  \n    &:disabled {\n      background-color: #2D2E2F;\n      cursor: default;\n    }\n  }\n  \n  @keyframes App-logo-spin {\n    from {\n      transform: rotate(0deg);\n    }\n  \n    to {\n      transform: rotate(360deg);\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

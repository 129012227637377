import React, { useState } from 'react'
import './pluginGrid.css';
import PluginItem from './PluginItem';
import pluginListData from '../data/pluginListData';

function PluginGrid({ navActive }) {
  const [pluginData, setPluginData] = useState(pluginListData);

  return (
    <div className='pluginGridWrapper'>

      <div className="gridContainer">

        {pluginData.filter(m => navActive !== 'all' ? m.type === navActive : m.type !== navActive).map(item =>
          <div className="gridItem">
            <div className="tile">
              <PluginItem key={item._id} item={item} />
            </div>
          </div>

        )}

      </div>


    </div>
  )
}

export default PluginGrid;


const navListData = [
    {
        _id: 1,
        target: 'strings',
        name: 'Strings Attached',
        type: 'synth',
        description: "Strings Attached brings the timeless Karplus-Strong algorithm into new realms, allowing for the creation of expressive, synthetic strings. Whether you're crafting rich, harmonic polyphonic chords or sharp percussive tones, this algorithm offers you ultimate flexibility. Adjust the dampening to achieve everything from plucked strings to shimmering, resonant textures. Its dynamic responsiveness makes it perfect for a range of genres, whether you're after lush cinematic soundscapes or cutting-edge experimental tones.",
        image: '/algorithm_strings_attached',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'StringsAttached.bin'
    },
    {
        _id: 2,
        target: 'flanger',
        name: 'Flanger Things',
        type: 'effect',
        description: "Flanger Things takes you on a sonic journey through the swirling vortex of classic flanger effects. Dial in the perfect amount of jet-plane swooshes and bouncing vibes with this versatile audio effect. Flanger Things isn't just for replicating classic rock tones. Explore the depths of its sonic manipulation and unearth novel textures. From subtle movement on clean guitars to warped leads and haunting vocals, Flanger Things is your gateway to aural experimentation.",
        image: '/algorithm_flanger_things',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'FlangerThings.bin'
    },
    {
        _id: 3,
        target: 'phaser',
        name: 'Familiar Phase',
        type: 'effect',
        description: "Introducing Familiar Phase, an expansive phaser effect designed to elevate your sound to new dimensions. With up to 40 stages in its phaser design, it offers unparalleled flexibility in crafting sonic landscapes. Adjust the rate of the LFO, the feedback amount, and more to finely shape the effect to your liking. Its rich and warm tones add a touch of nostalgia to any music, whether aiming for subtle movement or outer-space vibes. From adding a gentle wobble to chords to creating drastic, swirling effects, Familiar Phase is the perfect tool for adding depth and character to tracks.",
        image: '/algorithm_familar_phase',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'FamiliarPhase.bin'
    },
    {
        _id: 4,
        target: 'filter',
        name: 'Bobs Coffee Filter',
        type: 'effect',
        description: "Bob's Coffee Filter channels the warmth and character of the iconic transistor ladder filter, beloved in synthesizers since the 1970s. With the ability to adjust cutoff, resonance (including self-resonance), and drive, this filter wraps your sound in a smooth, analog warmth. It's the familiar sound that feels like home—soft, musical, and always inspiring. Whether shaping deep basslines or adding a gentle sweep to leads, Bob's Coffee Filter offers a timeless character that never goes out of style.",
        image: '/algorithm_bobs_coffee_filter',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'BobsCoffeeFilter.bin'
    },
    {
        _id: 5,
        target: 'reverb',
        name: 'Lost For Verbs',
        type: 'effect',
        description: "Lost For Verbs is a versatile reverb algorithm designed to provide a wide range of controls for creating lush and flexible reverberated sounds. With adjustable parameters such as predelay, reverb lushness, and simulated absorption to control the color of the reverberated sound, it offers flexibility in sculpting the sound. Whether you're aiming for a subtle, natural ambience or a larger atmospheric effect, Lost For Verbs invites for experimentation and creativity.",
        image: '/algorithm_lost_for_verbs',
        videoId: "XvGMg4a1XJI",
        released: true,
        fileName: 'LostForVerbs.bin'
    },
    {
        _id: 6,
        target: 'drive',
        name: 'Drive Me To The Moon',
        type: 'effect',
        description: "Drive Me To The Moon is a flexible overdrive algorithm that offers adjustable distortion intensity through its drive parameter. With smooth and musical distortion provided by a sophisticated soft clipping function, it enables users to explore a range of tones, from subtle warmth to aggressive bite. The simple, yet inviting, controls calls for experimentation, making it a perfect tool for crafting your desired sound.",
        image: '/algorithm_drive_me_to_the_moon',
        videoId: "-epoY91cl0w",
        released: true,
        fileName: 'DriveMeToTheMoon.bin'
    },
    {
        _id: 7,
        target: 'compressor',
        name: 'Compress The News',
        type: 'effect',
        description: "Compress The News is your go-to compressor for controlling dynamic range with precision and creativity. Dial in subtle compression for transparent, polished mixes, or push it harder for aggressive, dynamic reshaping of your sound. With full control over threshold, ratio, attack, and release, it fits into any production style, from glueing a mix together to reshaping individual elements. Whether you want to tame peaks or completely squash your signal, Compress The News offers the versatility to achieve any sound you need.",
        image: '/algorithm_compress_the_news',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'CompressTheNews.bin'
    },
    {
        _id: 8,
        target: 'sequencer',
        name: 'Temple Sequencer',
        type: 'tool',
        description: "Temple Sequencer taps into the mathematical beauty of Euclidean sequencing, offering a fresh way to create complex and natural rhythms. Spread hits evenly across a sequence to build intricate polyrhythms, or generate rhythmically engaging melodies that fit perfectly into musical scales. Ideal for drum programming and live performances, it enables real-time creation of sophisticated rhythmic patterns that evolve and surprise. From intricate drum grooves to evolving melodic sequences, Temple Sequencer is the key to unlocking creative rhythm in your music.",
        image: '/algorithm_temple_sequencer',
        videoId: "UpkEWhOTKLE",
        released: true,
        fileName: 'TempleSequencer.bin'
    },
    {
        _id: 9,
        target: 'drum',
        name: 'Rule Of Drum',
        type: 'synth',
        description: "Rule Of Drum is a FM-based drum machine, primed to become a stable in your percussive arsenal. It offers a wide range of sonic options with thick, punchy bass drums, crisp, cutting snares, a versatile range of shimmering hi-hats and shakers, and snappy, responsive toms. Each drum sound can be sculpted to your exact specifications, offering a spectrum of different sonic colours. A drum machine shouldn't stand all alone; Rule Of Drum seamlessly integrates with your creative workflow, offering both modern USB and classic DIN MIDI connectivity. Sequence it with your favorite MIDI sequencer, DAW, or even Temple Sequencer for Dubby – the choice is yours. With Rule Of Drum at your fingertips, prepare to experiment, explore, and forge your own rhythmic identity.",
        image: '/algorithm_rule_of_drum',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'RuleOfDrum.bin'
    },
    {
        _id: 10,
        target: 'delayfucker',
        name: 'He calls it: delayf**ker',
        type: 'effect',
        description: "<p><b>Concept:</b> The delayf**ker is a performance effect centered around abusing digital echo, where real-time manipulations get 'stuck' inside the feedback loop, allowing for sound-on-sound edits and dynamic audio interactions.</p><p><b>Sound Quality:</b> Delivers an esoteric timbre rooted in digital glitch, capable of spanning various delay subgenres such as Karplus-Strong, micro-looping, chorus, regular echo, and looping, creating rich and unpredictable audio textures.</p><p><b>Control and Flexibility:</b> Users can manipulate key parameters such as delay time, feedback, and delay buffer freezing, and randomize these settings for creative variations. It invites exploration on the brink of stability, producing outstanding sonic outputs.</p><p><b>Versatility and MIDI Compatibility:</b> Despite its esoteric nature, it offers a playable range from pretty to gritty, subtle to all-encompassing, and is MIDI sync-ready, making it one of the most versatile and interactive hardware delays available for both live performances and studio production.</p>",        
        image: '/algorithm_delayf--ker',
        videoId: "XrsIVXR9_nI",
        released: true,
        fileName: 'DelayFucker.bin'
    },
    {
        _id: 11,
        target: 'kingdubby',
        name: 'King Dubby Delay',
        type: 'effect',
        description: "King Dubby Delay pays homage to the legendary tape delays that defined the sound of dub music. With roots in the 1970s Jamaican studio scene, these delay units played a crucial role in shaping the psychedelic, spacious sound of dub. King Dubby Delay captures that essence, offering controls for delay time, feedback, and filtering. It's designed to bring the warmth of tape and the unpredictability of analog delay to your productions. Whether you're recreating the sound of vintage dub or adding a touch of organic warmth to modern tracks, this delay will have you reminiscing about warm weather, rolling tapes, and endless good vibes.",
        image: '/algorithm_king_dubby_delay',
        videoId: "Iu3sYf1pX0o",
        released: true,
        fileName: 'KingDubby.bin'
    },
    {
        _id: 12,
        target: 'midicontroller',
        name: 'Under Midi Control',
        type: 'tool',
        description: "Turn your Dubby into the ultimate MIDI controller with Under Midi Control. Map every knob, button, and joystick across 16 individually customizable pages. With compatibility for both USB and old-school DIN MIDI, it offers unparalleled flexibility for controlling your DAW, synths, or any MIDI-compatible gear. Whether you're in the studio or on stage, Under Midi Control ensures your Dubby can seamlessly integrate into any setup and perform just the way you need.",
        image: '/algorithm_under_midi_control',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'UnderMidiControl.bin'
    },
    {
        _id: 13,
        target: 'allatonce',
        name: 'All At Once',
        type: 'effect',
        description: "All At Once is our all-in-one multi-effect unit that combines reverb, delay, filter, and overdrive into a single, performance-ready package. Use our carefully crafted default mappings or create your own for ultimate control. Ideal for live setups, DJs, and musicians alike, All At Once is designed for hands-on manipulation, giving you the power to tweak your sound in real-time. It's your secret weapon for turning any performance into something unforgettable, with effects that flow seamlessly and interact in creative, inspiring ways.",
        image: '/algorithm_all_at_once',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'AllAtOnce.bin'
    },
    {
        _id: 14,
        target: 'check12',
        name: 'Check 1-2',
        type: 'tool',
        description: "Clean passthru with gain control.",
        image: '/algorithm_check_1-2',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'Clean.bin'
    },
    {
        _id: 15,
        target: 'kitchensank',
        name: 'Kitchen Sank',
        type: 'effect',
        description: "Kitchen Sank is a delay effect that thrives on feedback and real-time manipulation, making it the perfect tool for experimental performance. Its core feature is the ability to freeze the delay buffer, creating glitchy, looping effects that evolve with every movement. A built-in performance filter lets you shape the sound on the fly, while a simple button press engages the glitch buffer and locks feedback at 100%, building intense sonic layers. With another button, you can cut off the input to the buffer, allowing you to sculpt the glitchy chaos with precision. To top it off, Kitchen Sank features a sophisticated multi-stage saturator, adding rich distortion and grit to your delay effects, perfect for pushing your sound to new, crunk-filled heights. Ideal for those who want to push the limits of delay, Kitchen Sank is a must-have for adventurous sound designers and performers.",
        image: '/algorithm_kitchen_sank',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'KitchenSank.bin'
    },
    {
        _id: 16,
        target: 'beforeyou',
        name: 'Before You',
        type: 'effect',
        description: "Before You is a precision delay that excels at delivering sharp, clear, and richly textured delay effects. Its surgical timing allows you to explore a wide range of rhythmic divisions, perfect for everything from subtle echoes to complex delay patterns. You can freeze the delay buffer and manipulate its size while frozen, creating captivating pitch-shifting effects that add depth and movement to your sound. Designed for those who demand control, Before You syncs effortlessly with external gear and DAWs using MIDI clock, ensuring your delay fits perfectly into any tempo-driven setup, both live and in the studio.",
        image: '/algorithm_before_you',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'BeforeYou.bin'
    },
    {
        _id: 17,
        target: 'crushonyou',
        name: 'CrushOnYou',
        type: 'effect',
        description: "Before You is a precision delay that excels at delivering sharp, clear, and richly textured delay effects. Its surgical timing allows you to explore a wide range of rhythmic divisions, perfect for everything from subtle echoes to complex delay patterns. You can freeze the delay buffer and manipulate its size while frozen, creating captivating pitch-shifting effects that add depth and movement to your sound. Designed for those who demand control, Before You syncs effortlessly with external gear and DAWs using MIDI clock, ensuring your delay fits perfectly into any tempo-driven setup, both live and in the studio.",
        image: '/algorithm_crush_on_you',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'CrushOnYou.bin'
    },
    {
        _id: 18,
        target: 'highondrums',
        name: 'High On Drums',
        type: 'synth',
        description: "High On Drums features two Euclidian/algorithmic sequencers which create complex, polyrhythmic beats. With real-time controls for adjusting beat density, offset and length.",
        image: '/algorithm_high_on_drums',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'HighOnDrums.bin'
    },
    {
        _id: 19,
        target: 'ecstaticfm',
        name: 'Ecstatic FM',
        type: 'synth',
        description: "4-voice polyphonic FM synthesizer, with generative sequencer and major/minor selection for chords.",
        image: '/algorithm_ecstatic_fm',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'EcstaticFM.bin'
    },
    {
        _id: 20,
        target: 'acidfactory',
        name: 'Acid Factory',
        type: 'synth',
        description: "303 emulation bass monophonic synthesizer, with generative sequencer.",
        image: '/algorithm_acid_factory',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'AcidFactory.bin'
    },
    {
        _id: 21,
        target: 'simplethings',
        name: 'Simple Things',
        type: 'synth',
        description: "Simple Sine and Sawtooth oscillator",
        image: '/algorithm_simple_things',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'SimpleThings.bin'
    },
    {
        _id: 22,
        target: 'dualresonantfilter',
        name: 'Dual Resonant Filter',
        type: 'effect',
        description: "<p><b>Key characteristics:</b> <br/> Each filter (high-pass and low-pass) is a second-order IIR (Infinite Impulse Response) filter. <br/> The term 'biquad' comes from 'bi-quadratic', referring to the quadratic terms in both the numerator and denominator of the filter's transfer function. <br/> Our implementation uses the standard biquad structure derived from the bilinear transform of analog prototype filters.</p><p><b>Additional notes:</b> <br/> This implementation can be considered a digital approximation of a state-variable filter. <br/> The filter's response can be adjusted between Butterworth, Chebyshev, and other characteristics by changing the Q value. <br/> At Q ≈ 0.707, it approximates a Butterworth response. <br/> Higher Q values give a response more similar to Chebyshev filters. <br/> <br/> In technical terms, this as a 'Cascaded Biquad Filter with adjustable Q' or a 'Second-Order IIR Cascaded High-Pass/Low-Pass Filter.' <br/> <br/> The biquad structure is very common in digital audio processing due to its flexibility and efficiency. It's the standard building block for many types of digital filters, including Butterworth, Chebyshev, and others, with the specific type determined by the coefficient values.</p><p>",
        image: '/algorithm_dual_resonant_filter',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'DualResonantFilter.bin'
    },
    {
        _id: 23,
        target: 'manzasubdriver',
        name: 'Manza Sub Driver',
        type: 'effect',
        description: "Manza Sub Driver is a custom algorithm crafted in collaboration with sound system engineer Toby, aka Jah Highness of Copenhagen’s Dub Manza Sound System. This bespoke tool is designed to transform clean, sinusoidal basslines into warm, gritty, and textured sub-bass tones. As Toby put it, “I want my subs to sound like scoops… sometimes.” We took that vision and made it a reality, delivering a tool that brings the power and character of a classic scoop stack right into your Dubby.",
        image: '/algorithm_manza_sub_driver',
        videoId: "FN82UNIK8VQ",
        released: true,
        fileName: 'ManzaSubDriver.bin'
    },
    {
        _id: 24,
        target: 'fmdays',
        name: 'FM days',
        type: 'synth',
        description: "Lorem ipsum dolor sit amet. Qui eaque ipsam aut tenetur neque ea natus officia non galisum adipisci ea molestias minus. Qui soluta perspiciatis ut dolore tenetur ut labore neque ex dolorum expedita. Cum quia voluptates qui quibusdam aliquid et nobis quas.",
        image: '/algorithm_fm_days',
        videoId: "FN82UNIK8VQ",
        released: false,
        fileName: 'AllAtOnce.bin'
    },
    {
        _id: 25,
        target: 'crushhour',
        name: 'Crush Hour',
        type: 'effect',
        description: "Lorem ipsum dolor sit amet. Qui eaque ipsam aut tenetur neque ea natus officia non galisum adipisci ea molestias minus. Qui soluta perspiciatis ut dolore tenetur ut labore neque ex dolorum expedita. Cum quia voluptates qui quibusdam aliquid et nobis quas.",
        image: '/algorithm_crush_hour',
        videoId: "FN82UNIK8VQ",
        released: false,
        fileName: 'AllAtOnce.bin'
    },
    {
        _id: 26,
        target: 'laststep',
        name: 'Last Step',
        type: 'tool',
        description: "Lorem ipsum dolor sit amet. Qui eaque ipsam aut tenetur neque ea natus officia non galisum adipisci ea molestias minus. Qui soluta perspiciatis ut dolore tenetur ut labore neque ex dolorum expedita. Cum quia voluptates qui quibusdam aliquid et nobis quas.",
        image: '/algorithm_last_step',
        videoId: "FN82UNIK8VQ",
        released: false,
        fileName: 'AllAtOnce.bin'
    },
    {
        _id: 27,
        target: 'allatonce',
        name: 'Mixed Signals',
        type: 'tool',
        description: "Lorem ipsum dolor sit amet. Qui eaque ipsam aut tenetur neque ea natus officia non galisum adipisci ea molestias minus. Qui soluta perspiciatis ut dolore tenetur ut labore neque ex dolorum expedita. Cum quia voluptates qui quibusdam aliquid et nobis quas.",
        image: '/algorithm_mixed_signals',
        videoId: "FN82UNIK8VQ",
        released: false,
        fileName: 'AllAtOnce.bin'
    },
    {
        _id: 28,
        target: 'laststep',
        name: 'Noise Complaints',
        type: 'synth',
        description: "Lorem ipsum dolor sit amet. Qui eaque ipsam aut tenetur neque ea natus officia non galisum adipisci ea molestias minus. Qui soluta perspiciatis ut dolore tenetur ut labore neque ex dolorum expedita. Cum quia voluptates qui quibusdam aliquid et nobis quas.",
        image: '/algorithm_noise_complaints',
        videoId: "FN82UNIK8VQ",
        released: false,
        fileName: 'AllAtOnce.bin'
    },
    {
        _id: 29,
        target: 'shiftingwaves',
        name: 'Shifting Waves',
        type: 'synth',
        description: "Lorem ipsum dolor sit amet. Qui eaque ipsam aut tenetur neque ea natus officia non galisum adipisci ea molestias minus. Qui soluta perspiciatis ut dolore tenetur ut labore neque ex dolorum expedita. Cum quia voluptates qui quibusdam aliquid et nobis quas.",
        image: '/algorithm_shifting_waves',
        videoId: "FN82UNIK8VQ",
        released: false,
        fileName: 'AllAtOnce.bin'
    },
    {
        _id: 30,
        target: 'polyfriend',
        name: 'Poly Friend',
        type: 'synth',
        description: "Lorem ipsum dolor sit amet. Qui eaque ipsam aut tenetur neque ea natus officia non galisum adipisci ea molestias minus. Qui soluta perspiciatis ut dolore tenetur ut labore neque ex dolorum expedita. Cum quia voluptates qui quibusdam aliquid et nobis quas.",
        image: '/algorithm_poly_friend',
        videoId: "FN82UNIK8VQ",
        released: false,
        fileName: 'AllAtOnce.bin'
    },
];

export default navListData;



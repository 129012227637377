import React, { useState } from 'react';
import './App.css';
import HomePage from './pages/HomePage';
import Header from './components/Header';
import { Route, Routes, useLocation } from 'react-router-dom'; 
import PluginPage from './pages/PluginPage';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';

function App() {
  const [navActive, setNavActive] = useState('all');
  const [dubbyDeviceGlobal, setDubbyDeviceGlobal] = useState(null); 

  return (
    <div className='App'>
      <Header 
        navActive={navActive} 
        stateChanger={setNavActive} 
        stateChangerDevice={setDubbyDeviceGlobal} 
        showHeaderNav={useLocation().pathname === '/'}
      />
      <ScrollToTop /> {/* Ensure this is outside of <Routes> */}
      <Routes>
        <Route path="/" element={<HomePage navActive={navActive} />} />
        <Route path="/plugin/:pluginId" element={<PluginPage dubbyDeviceGlobal={dubbyDeviceGlobal} />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;

import React from 'react'
import './dubbyConnectedButton.css'

function DubbyConnectedButton( {connected }) {
  return (
    <div>
      <div className={connected ? "dubbyButtonConnected" : "dubbyButtonNotConnected"}>
        {connected ? (
            <p>CONNECTED</p>
          ) : (
            <p>NOT CONNECTED</p>
          )}
      </div>
    </div>
  )
}

export default DubbyConnectedButton
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pluginItem {
    width: 200px;
    height: 200px;
    margin: auto;
    position: relative;
    text-align: center;
}

.pluginImage {
    position: relative;
    width: 100%;
    width: 100%;
    left: 0;
}

.centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  body.active-modal {
    overflow-y: hidden;
}


.pluginLink {
    text-decoration: none;
    color: white;
}

.comingsoon {
  position: absolute;
  top: 0px;
  z-index: 1;
  width: 100%;
}

.comingsoon p {
  width: 70px;
  height: 8px;
  float: right;
  margin-right: 5px;
  border: 2px solid #212121;
  border-radius: 30px;
  color: #212121;
  height: 15px;
  padding: 1px 0px 0px 0px;
  font-size: 7px;
  font-weight: 900;
}

@media (max-width: 490px) {
  
    .pluginItem {
  
      width: 150px;
      height: 150px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/pluginItem.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,OAAO;AACX;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;EAClC;;EAEA;IACE,kBAAkB;AACtB;;;AAGA;IACI,qBAAqB;IACrB,YAAY;AAChB;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;EACV,WAAW;AACb;;AAEA;EACE,WAAW;EACX,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,yBAAyB;EACzB,mBAAmB;EACnB,cAAc;EACd,YAAY;EACZ,wBAAwB;EACxB,cAAc;EACd,gBAAgB;AAClB;;AAEA;;IAEI;;MAEE,YAAY;MACZ,aAAa;IACf;EACF","sourcesContent":[".pluginItem {\n    width: 200px;\n    height: 200px;\n    margin: auto;\n    position: relative;\n    text-align: center;\n}\n\n.pluginImage {\n    position: relative;\n    width: 100%;\n    width: 100%;\n    left: 0;\n}\n\n.centered {\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n  }\n\n  body.active-modal {\n    overflow-y: hidden;\n}\n\n\n.pluginLink {\n    text-decoration: none;\n    color: white;\n}\n\n.comingsoon {\n  position: absolute;\n  top: 0px;\n  z-index: 1;\n  width: 100%;\n}\n\n.comingsoon p {\n  width: 70px;\n  height: 8px;\n  float: right;\n  margin-right: 5px;\n  border: 2px solid #212121;\n  border-radius: 30px;\n  color: #212121;\n  height: 15px;\n  padding: 1px 0px 0px 0px;\n  font-size: 7px;\n  font-weight: 900;\n}\n\n@media (max-width: 490px) {\n  \n    .pluginItem {\n  \n      width: 150px;\n      height: 150px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

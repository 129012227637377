import React, { useState, useEffect } from 'react'
import './pluginPage.css';
import pluginListData from '../data/pluginListData';


import { useParams } from "react-router-dom";
import YouTubeItem from '../components/YouTubeItem';
import { bigFlash, flash_bytes_sent, installing, flash_expected_size, flash_bytes_erased, flash_bytes_to_erase } from '../dfu';


function PluginPage({dubbyDeviceGlobal}) {

    const { pluginId } = useParams();

    const [ plugin, setplugin ] = useState(pluginListData.filter(x => x._id == pluginId)[0]);
    const [ isInstalling, setIsInstalling ] = useState(false);

    const [ bytesSent, setBytesSent ] = useState(0);
    const [ bytesExpected, setBytesExpected ] = useState(0);

    const [ bytesErased, setBytesErased ] = useState(0);
    const [ bytesToErase, setBytesToErase ] = useState(0);


    useEffect(() => {

        
    }, [])

    const MINUTE_MS = 200;

    useEffect(() => {
        const interval = setInterval(() => {

        if (installing) {
            setIsInstalling(true);

            if (flash_bytes_erased > 0) {
                setBytesErased(flash_bytes_erased);
                setBytesToErase(flash_bytes_to_erase);
            }
            if (flash_bytes_sent > 0) {
                setBytesErased(0);
                setBytesToErase(0);
                setBytesSent(flash_bytes_sent);
                setBytesExpected(flash_expected_size);
            } 

        } else {
            setBytesErased(0);
            setBytesToErase(0);
            setBytesSent(0);
            setBytesExpected(0);
            setIsInstalling(false);
        }

    }, MINUTE_MS);

    return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
    }, [])

    const handleClick = async () => {

        await fetch(`../getbinfile?file=${plugin.fileName}`).then(
            response => {
                response.arrayBuffer()
                .then((arrayBuffer) => {
                    bigFlash(arrayBuffer);
                });
            }
            )
    };

  return (
    <div>
        <img src={plugin.image + ".png"} className="pluginPageImage" />
        <div className="pluginContent">

            <div className='pluginName'><h1>{plugin.name}</h1></div>
            
            <div className="actions">
                <div className="price">
                    <h2>Free</h2>
                </div>
                <div style={{width: '100%', height: '50px'}}>
                    <div className="pluginPageInstallBtn">
                        <button className="flashBtn" disabled={isInstalling || dubbyDeviceGlobal == null} onClick={handleClick}>Install</button>
                        <p className="flashingText">{isInstalling && (bytesToErase > 0 ? "Erasing..." : ( bytesExpected > 0 && "Flashing..."))}</p>
                    </div>
                    <br/>
                    <div className="wishlist">
                        {
                            isInstalling && (<progress value={bytesToErase > 0 ? (bytesErased / bytesToErase) : (bytesSent / bytesExpected)}/> )
                        }
                    </div>
                </div> 
                <br></br>
                <div className="texts">
                </div>

            </div>
            <div className="titleDescription" dangerouslySetInnerHTML={{ __html: plugin.description }} />
                 
     

            <div className="youtube">
                <YouTubeItem id={plugin.videoId} className="video" />
            </div>      
        </div>


    </div>
  )
}

export default PluginPage;

